/**=====================
     46. Tour  CSS Start
==========================**/
.bgjRaZ {
  background: $primary-color !important;
  height: unset !important;
}
.hqBKFk {
  color: $primary-color !important;
  background: $primary-color !important;
}
.gtkSUb {
  right: 18px !important;
}
/**=====================
    46. Tour  CSS Ends
==========================**/
