/**=====================
    48.  Ribbon CSS Start
==========================**/
.ribbon-wrapper,
.ribbon-wrapper-right,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding-top: 40px;
}
.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}
.ribbon-overflow {
  overflow: hidden;
}
.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}
.ribbon-wrapper-bottom {
  padding-bottom: 40px;
  padding-top: 0;
}
.ribbon-wrapper-right-bottom {
  padding-right: 40px;
}
.ribbon-content {
  margin-bottom: 0;
}
.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: $white;
  z-index: 1;
}
.ribbon-space-bottom {
  top: 0px;
}
.ribbon-clip {
  left: -14px;
  &:before {
    position: absolute;
    top: 30px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: $dark-color;
    border-right-color: $dark-color;
  }
}
.ribbon-bookmark {
  &:before {
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border: 15px solid $dark-color;
    border-right: 10px solid transparent;
  }
}
.ribbon-right {
  left: auto;
  right: -2px;
}
.ribbon-clip-right {
  right: -14px;
  &:before {
    position: absolute;
    top: 30px;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: $dark-color;
    border-right-color: $dark-color;
  }
}
.ribbon-clip-bottom {
  left: -14px;
  bottom: 12px;
  top: auto;
  &:before {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: $dark-color;
    border-right-color: $dark-color;
  }
}
.ribbon-clip-bottom-right {
  right: -14px;
  left: auto;
  bottom: 12px;
  top: auto;
  &:before {
    position: absolute;
    bottom: 30px;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    border: 7px solid transparent;
    border-top-color: $dark-color;
    border-right-color: $dark-color;
  }
}
.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid $dark-color;
  border-left: 10px solid transparent;
}
.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}
.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 12px;
}
.ribbon-bookmark {
  &.ribbon-vertical-left:before,
  &.ribbon-vertical-right:before {
    top: 100%;
    left: 0;
    margin-top: -14px;
    border-right: 15px solid $dark-color;
    border-bottom: 10px solid transparent;
  }
}
@each $ribbon-name, $ribbon-color in (primary, $primary-color),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color)
{
  .ribbon-#{$ribbon-name} {
    background: $ribbon-color;
  }
  .ribbon-#{$ribbon-name} {
    background: $ribbon-color;
  }
  .ribbon-bookmark {
    &.ribbon-#{$ribbon-name}:before {
      border-color: $ribbon-color;
      border-right-color: transparent;
    }
    &.ribbon-right.ribbon-#{$ribbon-name}:before {
      border-right-color: $ribbon-color;
      border-left-color: transparent;
    }
    &.ribbon-vertical-left.ribbon-#{$ribbon-name}:before,
    &.ribbon-vertical-right.ribbon-#{$ribbon-name}:before {
      border-right-color: $ribbon-color;
      border-bottom-color: transparent;
    }
  }
  .ribbon-#{$ribbon-name}.ribbon-corner {
    &:before {
      border-top-color: $ribbon-color;
      border-left-color: $ribbon-color;
    }
    &.ribbon-right:before {
      border-right-color: $ribbon-color;
      border-left-color: transparent;
    }
    &.ribbon-bottom:before {
      border-top-color: transparent;
      border-bottom-color: $ribbon-color;
    }
  }

  .ribbon-#{$ribbon-name}.ribbon-clip {
    &:before {
      border-color: transparent;
      border-top-color: darken($ribbon-color, 10%);
      border-right-color: darken($ribbon-color, 10%);
    }
  }
  .ribbon-#{$ribbon-name}.ribbon-clip-right {
    &:before {
      border-right-color: transparent;
      border-top-color: darken($ribbon-color, 10%);
      border-left-color: darken($ribbon-color, 10%);
    }
  }
  .ribbon-#{$ribbon-name}.ribbon-clip-bottom {
    &:before {
      border-top-color: transparent;
      border-bottom-color: darken($ribbon-color, 10%);
      border-right-color: darken($ribbon-color, 10%);
    }
  }
  .ribbon-#{$ribbon-name}.ribbon-clip-bottom-right {
    &:before {
      border-top-color: transparent;
      border-bottom-color: darken($ribbon-color, 10%);
      border-right-color: transparent;
      border-left-color: darken($ribbon-color, 10%);
    }
  }
}
/**=====================
     48. Ribbon CSS Ends
==========================**/
